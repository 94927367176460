import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>GimmeCard ТЧК</title>
        <meta property="og:title" content="GimmeCard ТЧК" />
      </Helmet>
      <div className="home-container2">
        <span className="home-text10">
          <span className="home-text11">
            Оформление международной банковской карты
          </span>
          <br></br>
          <br></br>
          <span>
            Вы переходите в сервис GimmeCard, где клиенты Точки могут заказать
            карту VISA иностранного банка с доставкой в Россию или другую
            страну.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span>
            Для подачи заявки на карту нужно заполнить анкету в боте. Предоплата
            не нужна. В Москву карта доставляется за 5 рабочих дней.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <a
            href="https://t.me/+kniMC4H19mA0OTMy"
            target="_blank"
            rel="noreferrer noopener"
          >
            <br className="home-text16"></br>
          </a>
          <br></br>
          <span>Перейти в бот можно по </span>
          <a
            href="https://t.me/gimmecard_bot?start=link_KtINnQo8q4"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link2"
          >
            ссылке
          </a>
          <span> или отсканировав QR-код.</span>
          <br></br>
        </span>
        <a
          href="https://t.me/gimmecard_bot?start=link_KtINnQo8q4"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link3"
        >
          <img alt="image" src="/qr-code-200h.png" className="home-image" />
        </a>
      </div>
    </div>
  )
}

export default Home
